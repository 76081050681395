import React from "react";
import "./ServicesCard.css";

export default function ServicesCard({services, isDark}) {
  function openUrlInNewTab(url) {
    if (url !== undefined) {
      var win = window.open(url, "_blank");
      win.focus();
    }
  }

  return (
    <div onClick={() => openUrlInNewTab(services.url)}>
      <div
        className={
          isDark ? "services-container dark-mode" : "services-container"
        }
      >
        <a
          className={
            isDark
              ? "dark-mode services-card services-card-shadow"
              : "services-card"
          }
          href="#services"
        >
          <h3
            className={isDark ? "small-dark services-title" : "services-title"}
          >
            {services.title}
          </h3>
          <p className={isDark ? "small-dark small" : "small"}>
            {services.description}
          </p>
          <div className="go-corner">
            <div className="go-arrow">→</div>
          </div>
          <span
            className="badge-latest"
            style={{display: services.isLatest ? "block" : "none"}}
          >
            {" "}
            New
          </span>
        </a>
      </div>
    </div>
  );
}
